require("./rainbowdraw.less");

const HTML = require("./rainbowdraw.html").default;
import BACKGROUND_IMG_SRC from "./background.png";
import { getOffset } from "../common/tools";

const MENU_WIDTH = 150;

const BRUSH_SIZES = [
    { size: 1, name: "small" },
    { size: 5, name: "medium" },
    { size: 15, name: "large" }
];

export class RainbowDraw {

    constructor() {
        this.gameName = "Rainbow Draw";
        this.icon = this.generateIcon();
    }

    generateIcon() {
        const iconCanvas = document.createElement("canvas");
        iconCanvas.height = 96;
        iconCanvas.width = 96;
        const ctx = iconCanvas.getContext("2d");
        var gradient = ctx.createLinearGradient(0, 0, 96, 96);
        gradient.addColorStop(0, "red");
        gradient.addColorStop(1 / 6, "orange");
        gradient.addColorStop(2 / 6, "yellow");
        gradient.addColorStop(3 / 6, "green");
        gradient.addColorStop(4 / 6, "blue");
        gradient.addColorStop(5 / 6, "indigo");
        gradient.addColorStop(1, "violet");
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, 96, 96);
        return iconCanvas.toDataURL("image/png");
    }

    play($mainStage, loadMenu) {
        this.width = $mainStage.width();
        this.height = $mainStage.height();
        this.selectedBrushSize = 0;
        this.canvasWidth = this.width - MENU_WIDTH;

        const instance = this;
        const $html = $(HTML);
        
        const $menu = $html.find(".side-menu")
                        .height(this.height)
                        .width(MENU_WIDTH);
        $menu.find("a.clear").click(event => {
            event.preventDefault();
            instance.resetOverlay();
        });
        $menu.find("a.main-menu").click(event => {
            event.preventDefault();
            loadMenu();
        });
        const $brushSizeLink = $menu.find("a.brush-size").click(event => {
            event.preventDefault();
            instance.selectedBrushSize = (this.selectedBrushSize+1) % BRUSH_SIZES.length;
            instance.updateBrushSizeName();
        });
        this.$brushSizeName = $brushSizeLink.find(".brush-size-name");
        this.updateBrushSizeName();

        this.$debug = $menu.find("#debug");

        this.setDimensions($html.find(".rainbowdraw-wrapper"));

        const $overlay = $html.find(".overlay");
        const overlay = $overlay.get(0);
        overlay.width = this.canvasWidth;
        overlay.height = this.height;
        this.overlayCxt = overlay.getContext("2d");

        const $background = $html.find(".background");
        const background = $background.get(0);
        background.width = this.canvasWidth;
        background.height = this.height;
        this.drawBackground(background);

        $menu.find("a.save").click(event => {
            event.preventDefault();
            const canvas = document.createElement("canvas");
            canvas.width = instance.canvasWidth;
            canvas.height = instance.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(background, 0, 0);
            ctx.drawImage(overlay, 0, 0);
            const image = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
            const link = document.createElement("a");
            const d = new Date();
            const datestring = d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate() + "_" + d.getHours() + d.getMinutes() + d.getSeconds();
            link.download = `rainbow-draw-${datestring}.png`;
            link.href = image;
            link.click();
        });

        this.resetOverlay();

        
        function beginDraw(event) {
            instance.drawing = true;
            var overlayOffset = getOffset(overlay);
            instance.lastMouseX = event.clientX - overlayOffset.left;
            instance.lastMouseY = event.clientY - overlayOffset.top;
            instance.draw(instance.lastMouseX, instance.lastMouseY, instance.lastMouseX + 1, instance.lastMouseY + 1);
        }

        function doDraw(event) {
            if(instance.drawing) {
                var overlayOffset = getOffset(overlay);
                var x = event.clientX - overlayOffset.left;
                var y = event.clientY - overlayOffset.top;
                if(instance.lastMouseX < 0) {
                    instance.lastMouseX = x - 1;
                    instance.lastMouseY = y - 1;
                }
                instance.draw(instance.lastMouseX, instance.lastMouseY, x, y);
                instance.lastMouseX = x;
                instance.lastMouseY = y;
            }
        }

        function resetMouseCoordinates() {
            instance.lastMouseX = -1;
            instance.lastMouseY = -1;
        }

        function endDraw() {
            instance.drawing = false;
            resetMouseCoordinates();
        }

        $overlay.mousedown(beginDraw);
        $overlay.mouseout(resetMouseCoordinates);
        $overlay.mouseup(endDraw);
        $overlay.mousemove(doDraw);
        overlay.addEventListener("touchstart", event => {
            beginDraw(event.touches[0]);
        });
        overlay.addEventListener("touchmove", event => {
            doDraw(event.touches[0]);
        });
        overlay.addEventListener("touchend", endDraw);

        $mainStage.append($html);
        $mainStage.fadeIn("slow");
    }

    drawBackground(canvas) {
        const img = new Image();
        img.onload = () => {
            const ctx = canvas.getContext("2d");
            const imageWidth = img.naturalWidth;
            const imageHeight = img.naturalHeight;
            for(let x = 0; x < canvas.width; x += imageWidth) {
                for(let y = 0; y < canvas.height; y += imageHeight) {
                    ctx.drawImage(img, x, y);
                }
            }
        };
        img.src = BACKGROUND_IMG_SRC;
    }

    updateBrushSizeName() {
        this.$brushSizeName.text(BRUSH_SIZES[this.selectedBrushSize].name);
    }

    draw(x1, y1, x2, y2) {
        this.overlayCxt.globalCompositeOperation = "destination-out";
        const radius = BRUSH_SIZES[this.selectedBrushSize].size;
        this.overlayCxt.beginPath();
        this.overlayCxt.arc(x1, y1, radius, 0, 2 * Math.PI);
        this.overlayCxt.fill();
        const a = x1 - x2;
        const b = y1 - y2;
        const c = Math.sqrt( a*a + b*b );
        if(c > 1) {
            this.overlayCxt.lineWidth = radius * 2;
            this.overlayCxt.beginPath();
            this.overlayCxt.moveTo(x1, y1);
            this.overlayCxt.lineTo(x2, y2);
            this.overlayCxt.stroke();
        }
        this.overlayCxt.beginPath();
        this.overlayCxt.arc(x2, y2, radius, 0, 2 * Math.PI);
        this.overlayCxt.fill();
        this.$debug.text(`X: ${x2}, Y: ${y2}`);
    }

    resetOverlay() {
        this.overlayCxt.globalCompositeOperation = "source-over";
        this.overlayCxt.fillStyle = "#000";
        this.overlayCxt.fillRect(0, 0, this.canvasWidth, this.height);
    }

    setDimensions($elems) {
        $elems.width(this.canvasWidth);
        $elems.height(this.height);
    }

}