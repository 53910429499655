// Adapted from:
// https://www.cssscript.com/confetti-falling-animation/

export default class Confetti {

	constructor(canvas) {
		this.maxCount = 150;		//set max confetti count
		this.speed = 2;			//set the particle animation speed
		this.frameInterval = 15;	//the confetti animation frame interval in milliseconds
		this.alpha = 1.0;			//the alpha opacity of the confetti (between 0 and 1, where 1 is opaque and 0 is invisible)
		this.gradient = false;	//whether to use gradients for the confetti particles
		this.supportsAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame;
		this.colors = ["rgba(30,144,255,", "rgba(107,142,35,", "rgba(255,215,0,", "rgba(255,192,203,", "rgba(106,90,205,", "rgba(173,216,230,", "rgba(238,130,238,", "rgba(152,251,152,", "rgba(70,130,180,", "rgba(244,164,96,", "rgba(210,105,30,", "rgba(220,20,60,"];
		this.streamingConfetti = false;
		this.animationTimer = null;
		this.pause = false;
		this.lastFrameTime = Date.now();
		this.particles = [];
		this.waveAngle = 0;
		this.canvas = canvas;
		this.context = canvas.getContext("2d");
	}

	resetParticle(particle, width, height) {
		particle.color = this.colors[(Math.random() * this.colors.length) | 0] + (this.alpha + ")");
		particle.color2 = this.colors[(Math.random() * this.colors.length) | 0] + (this.alpha + ")");
		particle.x = Math.random() * width;
		particle.y = Math.random() * height - height;
		particle.diameter = Math.random() * 10 + 5;
		particle.tilt = Math.random() * 10 - 10;
		particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
		particle.tiltAngle = Math.random() * Math.PI;
		return particle;
	}

	toggleConfettiPause() {
		if (this.pause)
			{this.resumeConfetti();}
		else
			{this.pauseConfetti();}
	}

	isConfettiPaused() {
		return this.pause;
	}

	pauseConfetti() {
		this.pause = true;
	}

	resumeConfetti() {
		this.pause = false;
		runAnimation();
	}

	runAnimation() {
		if (this.pause)
			{return;}
		else if (this.particles.length === 0) {
			this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.animationTimer = null;
		} else {
			var now = Date.now();
			var delta = now - this.lastFrameTime;
			if (!this.supportsAnimationFrame || delta > this.frameInterval) {
				this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
				this.updateParticles();
				this.drawParticles();
				this.lastFrameTime = now - (delta % this.frameInterval);
			}
			var instance = this;
			this.animationTimer = requestAnimationFrame(function() {
				instance.runAnimation();
			});
		}
	}

	startConfetti(timeout, min, max) {
		var width = this.canvas.width;
		var height = this.canvas.height;
		window.requestAnimationFrame = (function() {
			return window.requestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				window.oRequestAnimationFrame ||
				window.msRequestAnimationFrame ||
				function (callback) {
					return window.setTimeout(callback, this.frameInterval);
				};
		})();
		var count = this.maxCount;
		if (min) {
			if (max) {
				if (min == max)
					{count = this.particles.length + max;}
				else {
					if (min > max) {
						var temp = min;
						min = max;
						max = temp;
					}
					count = this.particles.length + ((Math.random() * (max - min) + min) | 0);
				}
			} else
				{count = this.particles.length + min;}
		} else if (max)
			{count = this.particles.length + max;}
		while (this.particles.length < count)
		{this.particles.push(this.resetParticle({}, width, height));}
		this.streamingConfetti = true;
		this.pause = false;
		this.runAnimation();
		if (timeout) {
			window.setTimeout(stopConfetti, timeout);
		}
	}

	stopConfetti() {
		this.streamingConfetti = false;
	}

	removeConfetti() {
		this.stopConfetti();
		this.pause = false;
		this.particles = [];
	}

	toggleConfetti() {
		if (this.streamingConfetti)
			{this.stopConfetti();}
		else
			{this.startConfetti();}
	}

	isConfettiRunning() {
		return this.streamingConfetti;
	}

	drawParticles() {
		var particle;
		var x, y, x2, y2;
		for (var i = 0; i < this.particles.length; i++) {
			particle = this.particles[i];
			this.context.beginPath();
			this.context.lineWidth = particle.diameter;
			x2 = particle.x + particle.tilt;
			x = x2 + particle.diameter / 2;
			y2 = particle.y + particle.tilt + particle.diameter / 2;
			if (this.gradient) {
				var gradient = this.context.createLinearGradient(x, particle.y, x2, y2);
				gradient.addColorStop("0", particle.color);
				gradient.addColorStop("1.0", particle.color2);
				this.context.strokeStyle = gradient;
			} else
				{this.context.strokeStyle = particle.color;}
			this.context.moveTo(x, particle.y);
			this.context.lineTo(x2, y2);
			this.context.stroke();
		}
	}

	updateParticles() {
		var width = this.canvas.width;
		var height = this.canvas.height;
		var particle;
		this.waveAngle += 0.01;
		for (var i = 0; i < this.particles.length; i++) {
			particle = this.particles[i];
			if (!this.streamingConfetti && particle.y < -15)
				{particle.y = height + 100;}
			else {
				particle.tiltAngle += particle.tiltAngleIncrement;
				particle.x += Math.sin(this.waveAngle) - 0.5;
				particle.y += (Math.cos(this.waveAngle) + particle.diameter + this.speed) * 0.5;
				particle.tilt = Math.sin(particle.tiltAngle) * 15;
			}
			if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
				if (this.streamingConfetti && this.particles.length <= this.maxCount)
					{this.resetParticle(particle, width, height);}
				else {
					this.particles.splice(i, 1);
					i--;
				}
			}
		}
	}

}

