require("./lava.less");
const HTML = require("./lava.html").default;
import { fadeOutAndEmpty, clearCanvas, setDimensions, copyDimensions, shuffleArray } from "../common/tools";
import { Sound } from "../common/sound";
import LAVA_IMG_SRC from "./lava_1024x476.png";
import LAVA_SOUND_SRC from "./DryLava.mp3";
import anime from "animejs/lib/anime.es.js";
import Confetti from "../common/confetti.js";
import icon from "./lava_icon.png";

const NUM_EQUATIONS = 4;
const FULL_LAVA_PX = -350;
const NUM_LAVA_LEVELS = 10;
const MAX_NUMBER = 25;
const MIN_NUMBER = 1;
const MAX_ANSWER = 50;
const MIN_ANSWER = 1;
const TIMER_RADIUS = 70;
const ODDS_OF_GETTING_PLUS = 1.0;
const TOTAL_TIMER_MILLIS = 60000;
const MAX_ATTEMPTS = 500;

function generateNumber() {
    return MIN_NUMBER + Math.floor((MAX_NUMBER-MIN_NUMBER) * Math.random());
}

function generateEquation(existingAnswers) {
    let equation, answer, attempts = 0;
    do {
        attempts++;
        if(attempts > MAX_ATTEMPTS) {
            throw `Exceeded maximum allowed number of attempts to generate an equation!: ${MAX_ATTEMPTS}`;
        }
        let a =  generateNumber();
        let b = generateNumber();
        const minus = Math.random() >= ODDS_OF_GETTING_PLUS;
        if(minus && (a - b < 0)) {
            let c = a;
            a = b;
            b = c;
        }
        equation =  `${a} ${minus ? "-" : "+"} ${b} = ▢`;
        if(minus) {
            b = b * -1;
        }
        answer = a + b;
    } while(answer > MAX_ANSWER || answer < MIN_ANSWER || existingAnswers.includes(answer));
    return {
        equation: equation,
        answer: answer
    }
}

export class Lava {

    constructor() {
        this.gameName = "Lava";
        this.icon = icon;
        this.lavaImage = new Image();
        this.lavaImage.src = LAVA_IMG_SRC;
        this.numEquations = NUM_EQUATIONS;
    }

    play($mainStage, loadMenu) {
        const instance = this;
        this.width = $mainStage.width();
        this.height = $mainStage.height();

        this.lavaSound = new Sound(LAVA_SOUND_SRC);
        this.lavaSound.init(0.5, true);

        this.$lavaWrapper = $(HTML);
        copyDimensions($mainStage, this.$lavaWrapper);

        this.$equationContainer = this.$lavaWrapper.find("#equation-container").hide();
        this.$answerContainer = this.$lavaWrapper.find("#answer-container").hide();

        this.$timerCanvas = this.$lavaWrapper.find("#timer-canvas").hide();
        setDimensions(150, 150, this.$timerCanvas);
        this.timerCanvas = this.$timerCanvas.get(0);

        this.$menuOverlay = this.$lavaWrapper.find("#menu-overlay").hide();
        this.$newGameMenu = this.$lavaWrapper.find("#new-game-menu").hide();
        this.$gameOverMenu = this.$lavaWrapper.find("#game-over-menu").hide();
        this.$wonGameMenu = this.$lavaWrapper.find("#won-game-menu").hide();
        this.$confettiCanvas = this.$lavaWrapper.find("#confetti-canvas").hide();
        copyDimensions($mainStage, this.$confettiCanvas);
        this.confetti = new Confetti(this.$confettiCanvas.get(0));

        this.$lavaWrapper.find("a.main-menu").click(event => {
            event.preventDefault();
            loadMenu();
        });
        this.$lavaWrapper.find("a.new-game").click(event => {
            event.preventDefault();
            instance.startNewGame($(event.target).parents(".menu"));
        });

        this.lavaCanvas = this.$lavaWrapper.find("#lava-canvas").get(0);
        this.lavaCanvas.width = this.width;
        this.lavaCanvas.height = this.height;
        const ctx = this.lavaCanvas.getContext("2d");
        const imageHeight = this.lavaImage.naturalHeight;
        ctx.drawImage(this.lavaImage, 0, this.height - imageHeight);
        
        this.lavaLevel = NUM_LAVA_LEVELS;
        this.updateLavaLevel(anim => {
            instance.showMenu(instance.$newGameMenu);
        });

        $mainStage.append(this.$lavaWrapper);
        $mainStage.fadeIn("fast");

    }

    initTimer() {
        clearCanvas(this.timerCanvas);
        const ctx = this.timerCanvas.getContext("2d");
        ctx.lineWidth = 5;
        ctx.strokeStyle = "blue";
        const x = this.timerCanvas.width / 2;
        const y = this.timerCanvas.height / 2;
        ctx.beginPath();
        ctx.arc(x, y, TIMER_RADIUS, 0, 2*Math.PI);
        ctx.stroke();
    }

    startTimer() {
        this.initTimer();
        const instance = this;
        instance.runTimer = true;
        instance.totalTimerRuntime = 0;
        instance.lastTimestamp = 0;
        const ctx = instance.timerCanvas.getContext("2d");
        const startRadians = 1.5*Math.PI;
        const x = instance.timerCanvas.width / 2;
        const y = instance.timerCanvas.height / 2;
        const doTimerUpdate = (timestamp) => {
            if(!instance.lastTimestamp) {
                instance.lastTimestamp = timestamp;
            } else {
                const timerDelta = timestamp - instance.lastTimestamp;
                instance.totalTimerRuntime += timerDelta;
                if(instance.totalTimerRuntime > TOTAL_TIMER_MILLIS) {
                    instance.runTimer = false;
                    instance.lavaLevel = NUM_LAVA_LEVELS;
                    instance.updateLavaLevel(anim => {
                        instance.showMenu(instance.$gameOverMenu);
                    });
                }
                if(!instance.runTimer) {
                    return;
                }
                const circlePct = instance.totalTimerRuntime / TOTAL_TIMER_MILLIS;
                const endRadians = startRadians + (2*Math.PI*circlePct);
                
                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.arc(x, y, TIMER_RADIUS, startRadians, endRadians);
                ctx.closePath();
                ctx.fillStyle = "#0000FF";
                ctx.fill();

                instance.lastTimestamp = timestamp;
            }
            requestAnimationFrame(doTimerUpdate);
        }
        requestAnimationFrame(doTimerUpdate);
    }

    stopTimer() {
        this.runTimer = false;
    }

    updateLavaLevel(callback, duration) {
        duration = duration || 500;
        const instance = this;
        instance.lavaSound.play();
        setTimeout(() => {
            instance.lavaSound.pause();
        }, duration + 1000);
        anime({
            targets: this.lavaCanvas,
            translateY: FULL_LAVA_PX * (this.lavaLevel / NUM_LAVA_LEVELS),
            easing: "easeInOutQuad",
            duration: duration,
            complete: callback
        });
    }

    startNewGame($menu) {
        this.hideMenu($menu);
        this.$timerCanvas.hide();
        const instance = this;
        this.lavaLevel = Math.floor(NUM_LAVA_LEVELS / 2);
        this.updateLavaLevel(anim => {
            instance.nextEquations();
        });
    }

    handleWrongAnswer() {
        this.lavaLevel++;
        this.updateLavaLevel();
        if(this.lavaLevel >= NUM_LAVA_LEVELS) {
            this.showMenu(this.$gameOverMenu);
        }
    }

    nextEquations() {
        const instance = this;
        fadeOutAndEmpty(instance.$equationContainer, () => {
            fadeOutAndEmpty(instance.$answerContainer, () => {
                instance.generateEquations();
                instance.$equationContainer.fadeIn("fast", () => {
                    instance.$answerContainer.fadeIn("fast", () => {    
                        instance.startTimer();
                        instance.$timerCanvas.fadeIn("fast");
                    });
                });
            });
        })
    }

    generateEquations() {
        const instance = this;
        let numAnswered = 0;
        let answers = [];
        for(let i = 0; i < this.numEquations; i++) {
            const equationAnswer = generateEquation(answers);
            answers.push(equationAnswer.answer);
            let div = document.createElement("div");
            div.classList = "equation";
            div.innerHTML = equationAnswer.equation;
            div.addEventListener("dragenter", e => {
                e.preventDefault();
                e.target.classList.add("over");
            }, false);
            div.addEventListener("dragover", e => {
                e.preventDefault();
            }, false);
            div.addEventListener("drop", e => {
                e.stopPropagation();
                e.target.classList.remove("over");
                const answerData = JSON.parse(e.dataTransfer.getData("text/plain"));
                if(answerData.answerValue === equationAnswer.answer) {
                    $("#"+answerData.answerId).fadeOut("fast");
                    div.classList.add("answered");
                    div.innerHTML = div.innerHTML.replace("▢", equationAnswer.answer);
                    numAnswered++;
                    if(numAnswered >= instance.numEquations) {
                        instance.stopTimer();
                        instance.lavaLevel--;
                        this.updateLavaLevel(anim => {
                            if(instance.lavaLevel <= 0) {
                                instance.$confettiCanvas.show();
                                instance.confetti.startConfetti();
                                instance.showMenu(instance.$wonGameMenu);
                            } else {
                                instance.nextEquations();
                            }
                        });
                    }
                } else if(!div.classList.contains("answered")) {
                    instance.handleWrongAnswer();
                }
            }, false);
            div.addEventListener("dragleave", e => {
                e.target.classList.remove("over");
            }, false);
            instance.$equationContainer.append(div);
        }
        shuffleArray(answers);
        for(let i = 0; i < this.numEquations; i++) {
            let answer = answers[i];
            let div = document.createElement("div");
            div.id = "answer"+i;
            div.classList = "answer";
            div.innerHTML = answer;
            div.draggable = true;
            div.addEventListener("dragstart", e => {
                e.target.style.opacity = 0.4;
                e.dataTransfer.effectAllowed = "move";
                e.dataTransfer.setData("text/plain", JSON.stringify({
                    answerId: div.id,
                    answerValue: answer
                }));
            }, false);
            div.addEventListener("dragend", e => {
                e.target.style.opacity = "";
            }, false);
            instance.$answerContainer.append(div);
        }
    }

    hideMenu($menu) {
        if($menu) {
            const instance = this;
            function doFadeOut() {
                $menu.fadeOut("fast", ()=> {
                    instance.$menuOverlay.fadeOut("fast");
                });
            }
            if(instance.confetti.isConfettiRunning()) {
                instance.$confettiCanvas.fadeOut("fast", () => {
                    instance.confetti.removeConfetti();
                    doFadeOut();
                }); 
            } else {
                doFadeOut();
            }
        }
    }

    showMenu($menu) {
        this.$menuOverlay.fadeIn("fast", ()=> {
            $menu.fadeIn("fast");
        });
    }

}