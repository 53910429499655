/*
 * source: https://stackoverflow.com/a/11331027
 */
export class Sound {

    constructor(source) {
        this.source = source;
    }

    init(volume, loop) {
        this.soundFile = document.createElement("audio");
        this.soundFile.preload = "auto";
        this.soundFile.loop = loop;
        this.soundFile.volume = volume;
        this.soundFileSource = document.createElement("source");
        this.soundFileSource.src = this.source;
        this.soundFile.appendChild(this.soundFileSource);
    }

    play() {
        this.soundFile.play();
    }

    pause() {
        this.soundFile.pause();
    }

}