require("./butterflies.less");
import icon from "./butterflies_icon.png";
import { copyDimensions, doUnwrapped, getWrapped } from "../common/tools";
import type1 from "./img/butterfly #1.png";
import type2 from "./img/butterfly #2.png";
import type3 from "./img/butterfly #3.png";
import anime from "animejs/lib/anime.es.js";

const ButterflyState = {
    FLYING_TO_PATTERN: "FLYING_TO_PATTERN",
    FLYING_PATTERN: "FLYING_PATTERN",
    FLYING_TO_CAGE: "FLYING_TO_CAGE",
    IN_CAGE: "IN_CAGE"
}

const CIRCLE_CENTER_X = 700;
const CIRCLE_CENTER_Y = 200;
const CIRCLE_RADIUS = 110;

const CAGE_X = 710;
const CAGE_Y = 500;

const SCREEN_MIDDLE_Y = "300px";

const SHOW_HIDE_DURATION = 500;
const SHOW_HIDE_PERCENT = "500%";

function hideElement($elem) {
    showHideElement($elem, true);
}
function showElement($elem) {
    showHideElement($elem, false);
}
function showHideElement($elem, hide) {
    $elem = getWrapped($elem);
    let top = $elem.css("top");
    let bottom = $elem.css("bottom");
    let topElement = top ? top <= SCREEN_MIDDLE_Y : bottom ? bottom > SCREEN_MIDDLE_Y : true;
    let translateX = ["0","0"];
    let centeredX = $elem.hasClass("butterfly") || $elem.hasClass("cage") || $elem.hasClass("coin");
    if(centeredX) {
        translateX = ["-50%", "-50%"];
    }
    let hiddenY = SHOW_HIDE_PERCENT;
    if(topElement) {
        hiddenY = "-"+hiddenY;
    }
    let originY = "0";
    let centeredY = $elem.hasClass("butterfly");
    if(centeredY) {
        originY = "-50%";
    }
    let translateY;
    if(hide) {
        translateY = [originY,hiddenY];
    } else {
        translateY = [hiddenY,originY];
    }
    doUnwrapped($elem, elem => {
        let props = {
            targets: elem,
            translateY,
            translateX,
            easing: "easeInOutQuad",
            duration: SHOW_HIDE_DURATION
        };
        anime(props);
    });
}

function shiftX($elem, toFrom) {
    if(Array.isArray($elem)) {
        $elem.forEach(element => shiftX(element, toFrom));
    } else {
        doUnwrapped($elem, elem => {
            let props = {
                targets: elem,
                translateX: toFrom,
                easing: "easeInOutQuad",
                duration: SHOW_HIDE_DURATION
            };
            anime(props);
        });
    }
}

class Butterfly {
    constructor(main, type, img, x, y, speedPixPerSec) {
        this.main = main;
        this.type = type;
        this.img = img;
        this.x = x;
        this.y = y;
        this.speedPixPerSec = speedPixPerSec;
        this.state = ButterflyState.FLYING_TO_PATTERN;
        this.targetX = CIRCLE_CENTER_X + CIRCLE_RADIUS;
        this.targetY = CIRCLE_CENTER_Y;
    }
    updatePosition(delta) {
        // delta ~16
        if(delta > 0) {
            let numDeltas = delta / 16.666;
            let pixelsToMove = (this.speedPixPerSec * numDeltas);
            if(this.state == ButterflyState.FLYING_TO_PATTERN || this.state == ButterflyState.FLYING_TO_CAGE) {
                let dx = this.targetX - this.x;
                let dy = this.targetY - this.y;
                let angle = Math.atan(dx/dy);
                let dtotal = dx / Math.sin(angle);
                if(Math.abs(dtotal) > pixelsToMove) {
                    let dx1 = Math.sin(angle) * pixelsToMove;
                    this.x += dx1;
                    let dy1 = Math.cos(angle) * pixelsToMove;
                    this.y += dy1;
                } else {
                    switch(this.state) {
                        case ButterflyState.FLYING_TO_PATTERN:
                            this.state = ButterflyState.FLYING_PATTERN;
                            this.angle = 0;
                            break;
                        case ButterflyState.FLYING_TO_CAGE:
                            this.state = ButterflyState.IN_CAGE;
                            this.main.cageButterfly(this);
                            break;
                    }
                }
            } else if(this.state == ButterflyState.FLYING_PATTERN) {
                this.x = CIRCLE_CENTER_X + Math.cos(this.angle) * CIRCLE_RADIUS;
                this.y = CIRCLE_CENTER_Y + Math.sin(this.angle) * CIRCLE_RADIUS;
                this.angleD = pixelsToMove * 360 / (2 * Math.PI * CIRCLE_RADIUS) * (Math.PI / 180);
                this.angle += this.angleD;
                if(this.angle > 360) {
                    this.angle = 0;
                }
            }
            this.img.style.left = this.x + "px";
            this.img.style.top = this.y + "px";
        }
    }
    checkIfCaught(target) {
        if(this.img == target
             && this.state != ButterflyState.FLYING_TO_CAGE
             && this.state != ButterflyState.IN_CAGE) {
            this.state = ButterflyState.FLYING_TO_CAGE;
            this.targetX = CAGE_X;
            this.targetY = CAGE_Y;
            this.speedPixPerSec = 15;
            return true;
        }
        return false;
    }
    hide() {
        hideElement(this.img);
    }
    show() {
        showElement(this.img);
    }
}

export class Butterflies {

    constructor() {
        this.gameName = "Catch the Butterflies";
        this.icon = icon;
    }

    play($mainStage, loadMenu) {
        this.width = $mainStage.width();
        this.height = $mainStage.height();
        
        this.$htmlWrapper = $(require("./butterflies.html").default);
        copyDimensions($mainStage, this.$htmlWrapper);
        $mainStage.append(this.$htmlWrapper);
        $mainStage.fadeIn("fast");

        this.$eyes = this.$htmlWrapper.find(".eyes");
        this.$bow = this.$htmlWrapper.find(".bow");
        this.$face = this.$htmlWrapper.find(".face");
        this.$ears = this.$htmlWrapper.find(".ears");
        this.$dress = this.$htmlWrapper.find(".dress");
        this.$nose = this.$htmlWrapper.find(".nose");
        this.bodyParts = [
            this.$eyes,
            this.$bow,
            this.$face,
            this.$ears,
            this.$dress,
            this.$nose
        ];
        this.$tableLeft = this.$htmlWrapper.find(".table-left");
        this.$tableRight = this.$htmlWrapper.find(".table-right");

        this.$counter = this.$htmlWrapper.find(".counter");
        this.$counterLine = this.$htmlWrapper.find(".counter-line");

        let instance = this;
        instance.animationGlobalId = 0;
        this.$backButton = this.$htmlWrapper.find(".back-button").click(event => {
            event.preventDefault();
            if(instance.animationGlobalId > 0) {
                cancelAnimationFrame(instance.animationGlobalId);
            }
            loadMenu();
        });
        showElement(this.$backButton);

        this.$makeYourCharButton = this.$htmlWrapper.find(".make-your-char-button").click(event => {
            event.preventDefault();
            hideElement(this.$makeYourCharButton);
            hideElement(this.$backButton);
            showElement(this.$menuBackButton);
            hideElement(this.$cage);
            shiftX(this.bodyParts, [0, "190px"]);
            shiftX(this.$tableLeft, [0, "350px"]);
            shiftX(this.$tableRight, [0, "-350px"]);
            this.butterflies.forEach(butterfly => {
                butterfly.hide();
            });
            this.$counter.fadeOut("fast");
            this.$counterLine.fadeOut("fast");
        });
        showElement(this.$makeYourCharButton);

        this.$menuBackButton = this.$htmlWrapper.find(".menu-back-button").click(event => {
            event.preventDefault();
            showElement(this.$makeYourCharButton);
            showElement(this.$backButton);
            hideElement(this.$menuBackButton);
            showElement(this.$cage);
            shiftX(this.bodyParts, ["190px", 0]);
            shiftX(this.$tableLeft, ["350px", 0]);
            shiftX(this.$tableRight, ["-350px", 0]);
            this.butterflies.forEach(butterfly => {
                butterfly.show();
            });
            this.$counter.fadeIn("slow");
            this.$counterLine.fadeIn("slow");
        });

        this.$htmlWrapper.bind("click", function(event){
            instance.checkForButterflyTrap(event.target);
        });

        this.$htmlWrapper.bind("touchstart", function(event){
            instance.checkForButterflyTrap(event.target);
        });

        this.$cage = this.$htmlWrapper.find(".cage");
        showElement(this.$cage);

        this.$coin = this.$htmlWrapper.find(".coin");
        showElement(this.$coin);

        this.butterflies = [];
        
        this.makeButterfly(type1);
        this.makeButterfly(type2);
        this.makeButterfly(type3);
        
        this.numCaught = localStorage.getItem("numCaught") || 0;
        this.$counter.html(this.numCaught);

        this.startGame();

    }

    makeButterfly(type) {
        let butterflyImg = new Image();
        butterflyImg.src = type;
        butterflyImg.classList = "butterfly";
        this.$htmlWrapper.append(butterflyImg);
        let startX = 200 + Math.random() * 800;
        let speed = 1 + 5 * Math.random();
        this.butterflies.push(new Butterfly(this, type, butterflyImg, startX, -100, speed));
    }

    cageButterfly(butterfly) {
        if(this.butterflies.length > 13) {
            let idx = this.butterflies.indexOf(butterfly);
            if(idx > -1) {
                this.butterflies.splice(idx, 1);
            }
            $(butterfly.img).remove();
        }
    }

    startGame() {
        let instance = this;
        let lastTime;
        function nextTick(timestamp){
            var delta = timestamp - lastTime
            instance.butterflies.forEach(butterfly => {
                butterfly.updatePosition(delta);
            });
            lastTime = timestamp;
            instance.animationGlobalId = requestAnimationFrame((timestamp) => {
                nextTick(timestamp)
            })
        }
        instance.animationGlobalId = requestAnimationFrame((timestamp) => {
            lastTime = timestamp;
            nextTick(timestamp) // 400px over 1 second
        });
    }

    increaseCaught() {
        this.numCaught++;
        this.$counter.html(this.numCaught);
        localStorage.setItem("numCaught", this.numCaught);
    }

    checkForButterflyTrap(target) {
        let instance = this;
        this.butterflies.forEach(butterfly => {
            if(butterfly.checkIfCaught(target)) {
                instance.increaseCaught();
                instance.makeButterfly(butterfly.type);
            }
        });
    }

}