require("./treasurehunt.less");
const HTML = require("./treasurehunt.html").default;
import { copyDimensions, shuffleArray } from "../common/tools";
import icon from "./treasurehunt.png";

const SECRETS = {
    "XAKZ": "Check Daddy's pillow",
    "ZSST": "Check Mommy's pillow",
    "VDXP": "Look on top of the potty downstairs",
    "PEUP": "Look in the shower upstairs in the kid's bathroom",
    "HTQK": "Look on the dining room table",
    "HHNI": "Look on Cora's bookshelf",
    "WKJL": "Look on the foyer table downstairs",
    "TFPV": "Look on Cora's bed in sissy's room",
    "OLXT": "Check the mailbox",
    "CPNS": "Check the foyer table",
    "JYZC": "Check the grey chair",
    "AJLS": "Check the easel",
    "WAQZ": "Check the black stool",
    "GMNX": "Check the washing machine",
    "Q2Q5": "Look in the refrigerator snack drawer",
    "22AC": "Check Mommy's stocking",
    "HCYY": "Look under the gray chair",
    "6JZJ": "Look under the napkin holder",
    "WOXT": "Check the little Santa reading the book",
    "KWCK": "Look under the Olaf pillow",
    "M5UO": "Check Daddy'stocking",
    "UGAR": "Check where mommy and daddy sleep"
}

const CLUES = [
    "Check Daddy's pillow",
    "Check Mommy's pillow",
    "Look on top of the potty downstairs",
    "Look in the shower upstairs in the kid's bathroom",
    "Look on the dining room table",
    "Look on Cora's bookshelf",
    "Look on the foyer table downstairs",
    "Look on Cora's bed in sissy's room",
    "Check the mailbox",
    "Check the foyer table",
    "Check the grey chair",
    "Check the easel",
    "Check the black stool",
    "Check the washing machine",
    "Look in the refrigerator snack drawer",
    "Check Mommy's stocking",
    "Look under the gray chair",
    "Look under the napkin holder",
    "Check the little Santa reading the book",
    "Look under the Olaf pillow",
    "Check Daddy'stocking"
];

export class TreasureHunt {

    constructor() {
        this.gameName = "Treasure Hunt";
        this.icon = icon;
    }

    play($mainStage, loadMenu) {
        const instance = this;
        const $treasurehuntWrapper = instance.$treasurehuntWrapper = $(HTML);
        copyDimensions($mainStage, $treasurehuntWrapper);

        this.$treasurehuntWrapper.find("a.main-menu").click(event => {
            event.preventDefault();
            loadMenu();
        });
        this.$treasurehuntWrapper.find("a.new-game").click(event => {
            event.preventDefault();
            instance.startNewHunt($(event.target).parents(".menu"));
        });

        /*
        this.$menuOverlay = $treasurehuntWrapper.find("#menu-overlay");
        this.$pickClueMenu = $treasurehuntWrapper.find("#pick-clue-menu").hide();
        this.$clueNumberSpan = this.$pickClueMenu.find("#clue-number");
        this.$clueOptionList = this.$pickClueMenu.find("#clue-option-list");
        
        $treasurehuntWrapper.find("#secret-input-container").hide();
        */
        const $secretInput = $treasurehuntWrapper.find("#secret-input");
        const $secretAnswer = $treasurehuntWrapper.find("#secret-answer");
        const handleNewSecret = () => {
            const secretCode = $secretInput.val().toUpperCase();
            const secretFound = SECRETS[secretCode];
            const secretText = secretFound || "Invalid secret code!";
            $secretAnswer.fadeOut("slow", () => {
                if(secretFound) {
                    $secretInput.val("");
                }
                $secretAnswer.html(secretText);
                $secretAnswer.fadeIn("slow");
            });
        };
        $secretInput.keypress(event => {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            // 13 is 'enter' key
            if (keycode == "13") {
                handleNewSecret();
            }
        });
        $secretInput.keyup(event => {
            let secretVal = $secretInput.val();
            secretVal = secretVal.replace(/[^a-zA-Z0-9]+/g, "");
            $secretInput.val(secretVal);
            if(secretVal.length === 4) {
                handleNewSecret();
            }
        })

        $mainStage.append($treasurehuntWrapper);
        $mainStage.fadeIn("fast", () => {    
            $secretInput.focus();
        });
    }

    startNewHunt($menu) {
        $menu.fadeOut("slow");
        let clueNum = 1;
        let remainingClues = [...CLUES];
        this.$clueNumberSpan.html(clueNum);
        shuffleArray(remainingClues);
        let currentClues = remainingClues.slice(0,6);
        let options = "";
        
        this.$pickClueMenu.fadeIn("slow");

    }

}