export function getOffset( el ) {
    var _x = 0;
    var _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}

export function copyDimensions(source, dest) {
    if(source instanceof jQuery) {
        setDimensions(source.width(), source.height(), dest);
    } else if(source.hasOwnProperty("width") && source.hasOwnProperty("height")) {
        setDimensions(source.width, source.height, dest);
    } else {
        throw `Unable to find width and height properties on source object type: ${typeof source}, value: ${source}`;
    }
}

export function setDimensions(width, height, obj) {
    if(obj instanceof jQuery) {
        obj.each((index, element) => {
            setDimensions(width, height, element);
        });
    } else if (obj instanceof HTMLElement) {
        if("canvas" === obj.tagName.toLowerCase()) {
            obj.width = width;
            obj.height = height;
        } else {
            const $elem = $(obj);
            $elem.width(width);
            $elem.height(height);
        }
    } else {
        throw `Unknown object type: ${typeof obj}, value: ${obj}`;
    }
}

export function getWrapped(obj) {
    if(obj instanceof jQuery) {
        return obj;
    }
    return $(obj);
}

export function doUnwrapped(obj, doFunc) {
    if(obj instanceof jQuery) {
        obj.each((index, element) => {
            doFunc(element);
        });
    } else {
        doFunc(obj);
    }
}

export function clearCanvas($canvas) {
    doUnwrapped($canvas, (canvas) => {
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    });
}

export function fadeOutAndEmpty($target, callback, speed) {
    speed = speed || "fast";
    $target.fadeOut(speed, () => {
        $target.empty();
        callback();
    });
}

export function setClick($root, selector, clickAction) {
    $root.find(selector).click(event => {
        event.preventDefault();
        clickAction(event);
    });
}

/*
 * Adapted from:
 * https://stackoverflow.com/a/2450976
 */
export function shuffleArray(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
}