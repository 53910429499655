// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rainbowdraw-wrapper {
  position: relative;
}
.rainbowdraw-wrapper .side-menu {
  position: absolute;
  right: 0;
}
.rainbowdraw-wrapper .side-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rainbowdraw-wrapper .side-menu ul li {
  text-align: center;
}
.rainbowdraw-wrapper .side-menu ul li a {
  display: block;
  box-sizing: border-box;
  padding: 10px;
  text-decoration: none;
}
.rainbowdraw-wrapper .side-menu ul li a:hover {
  background-color: #0000ee;
  color: white;
}
.rainbowdraw-wrapper .side-menu #debug {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.rainbowdraw-wrapper .background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 150;
}
.rainbowdraw-wrapper .overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 250;
  cursor: crosshair;
}
`, "",{"version":3,"sources":["webpack://./src/rainbowdraw/rainbowdraw.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAFA;EAIQ,kBAAA;EACA,QAAA;AACR;AANA;EAOY,gBAAA;EACA,SAAA;EACA,UAAA;AAEZ;AAXA;EAWgB,kBAAA;AAGhB;AAdA;EAaoB,cAAA;EACA,sBAAA;EACA,aAAA;EACA,qBAAA;AAIpB;AAHoB;EACI,yBAAA;EACA,YAAA;AAKxB;AAxBA;EA0BY,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;AACZ;AA9BA;EAkCQ,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;AADR;AApCA;EAyCQ,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,iBAAA;AAFR","sourcesContent":[".rainbowdraw-wrapper {\n    position: relative;\n\n    .side-menu {\n        position: absolute;\n        right: 0;\n        ul {\n            list-style: none;\n            margin: 0;\n            padding: 0;\n            li {\n                text-align: center;\n                a {\n                    display: block;\n                    box-sizing: border-box;\n                    padding: 10px;\n                    text-decoration: none;\n                    &:hover {\n                        background-color: rgb(0, 0, 238);\n                        color: white;\n                    }\n                }\n            }\n        }\n        \n        #debug {  \n            text-align: center;\n            position: absolute;\n            bottom: 0;\n            width: 100%;\n        }\n    }\n\n    .background {\n        position: absolute;\n        top: 0;\n        left: 0;\n        z-index: 150;\n    }\n\n    .overlay {\n        position: absolute;\n        top: 0;\n        left: 0;\n        z-index: 250;\n        cursor: crosshair;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
