require("./main.less");

import { Maze } from "../maze/maze.js";
import { RainbowDraw } from "../rainbowdraw/rainbowdraw.js";
import { Lava } from "../lava/lava.js";
import { Memory } from "../memory/memory.js";
import { TreasureHunt } from "../treasurehunt/treasurehunt.js";
import { Butterflies } from "../butterflies/butterflies.js";
import LOGO_SRC from "./logo64.png";

import MAIN_HTML from "./main.html";
const $mainMenu = $(MAIN_HTML);

const mainStage = document.getElementById("main-stage");

const hostnamePrefix = window.location.hostname.split(".")[0];
const theme = hostnamePrefix === "localhost" ? "corasworld" : hostnamePrefix;

$("body").addClass("themed");
import("../themes/" + theme + "/main.less");
import("../themes/" + theme + "/config.json").then(({ default: config }) => {
    $(document).prop("title", config.title);
    const header = $mainMenu.find("h1");
    header.text(config.title);
    const logoImg = new Image();
    logoImg.onload = () => {
        header.prepend(logoImg);
    };
    logoImg.src = LOGO_SRC;

});


const $mainStage = $(mainStage);
const games = [new Maze(), new RainbowDraw(), new Lava(), new Memory(), new TreasureHunt(), new Butterflies()];
function findGame(gameName) {
    if(gameName) {
        gameName = decodeURIComponent(gameName);
        for(let i = 0; i < games.length; i++) {
            const game = games[i];
            if(game.gameName === gameName) {
                return game;
            }
        }
    }
    return null;
}

function loadMenu() {
    history.replaceState(null, null, " ");
    $mainStage.empty();
    let $menuList = $mainMenu.find("#main-menu");
    $menuList.empty();
    $mainStage.append($mainMenu);
    function loadGameMenuItem(i) {
        if(i >= games.length) {
            return;
        }
        let game = games[i];
        let iconImg = new Image();
        iconImg.src = game.icon;
        let $entry = $("<li></li>");
        $entry.hide();
        let $entryLink = $("<a href></a>");
        $entry.append($entryLink);
        $entryLink.append(iconImg);
        $entryLink.append("<p class=\"game-name\">"+game.gameName+"</li>");
        $entryLink.click(function(event) {
            event.preventDefault();
            window.location.hash = game.gameName;
            $mainStage.fadeOut("slow", function() {
                $mainStage.empty();
                game.play($mainStage, function(){
                    $mainStage.fadeOut("slow", function(){
                        $mainStage.show();
                        loadMenu();
                    });
                });
            });
        });
        $menuList.append($entry);
        $entry.fadeIn("fast", function(){
            loadGameMenuItem(i+1);
        });
    }
    setTimeout(function() {
        loadGameMenuItem(0)
    }, 500);
}

const game = findGame(window.location.hash.split("#")[1]);
if(game) {
    $mainStage.hide();
    game.play($mainStage, function(){
        $mainStage.fadeOut("slow", function(){
            $mainStage.show();
            loadMenu();
        });
    });
} else {
    loadMenu();
}

window.addEventListener("hashchange", function(){
    if(!window.location.hash) {
        loadMenu();
    }
});
