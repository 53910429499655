// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("./img/make your char button.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("./img/back button.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("./img/back button 2.png", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("./img/kitty main.png", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("./img/dress.png", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("./img/ears.png", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("./img/nose mouth.png", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("./img/bow.png", import.meta.url);
var ___HTML_LOADER_IMPORT_8___ = new URL("./img/eyes.png", import.meta.url);
var ___HTML_LOADER_IMPORT_9___ = new URL("./img/Counter line.png", import.meta.url);
var ___HTML_LOADER_IMPORT_10___ = new URL("./img/coin_100x80.png", import.meta.url);
var ___HTML_LOADER_IMPORT_11___ = new URL("./img/cage.png", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var ___HTML_LOADER_REPLACEMENT_6___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_6___);
var ___HTML_LOADER_REPLACEMENT_7___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_7___);
var ___HTML_LOADER_REPLACEMENT_8___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_8___);
var ___HTML_LOADER_REPLACEMENT_9___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_9___);
var ___HTML_LOADER_REPLACEMENT_10___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_10___);
var ___HTML_LOADER_REPLACEMENT_11___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_11___);
var code = "<div class=\"butterflies\"> <img class=\"make-your-char-button\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> <img class=\"back-button\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"/> <img class=\"menu-back-button\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"/> <img class=\"face\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"/> <img class=\"dress\" src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\"/> <img class=\"ears\" src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\"/> <img class=\"nose\" src=\"" + ___HTML_LOADER_REPLACEMENT_6___ + "\"/> <img class=\"bow\" src=\"" + ___HTML_LOADER_REPLACEMENT_7___ + "\"/> <img class=\"eyes\" src=\"" + ___HTML_LOADER_REPLACEMENT_8___ + "\"/> <img class=\"counter-line\" src=\"" + ___HTML_LOADER_REPLACEMENT_9___ + "\"/> <div class=\"counter\"></div> <div class=\"table table-left\"></div> <div class=\"table table-right\"></div> <img class=\"coin\" src=\"" + ___HTML_LOADER_REPLACEMENT_10___ + "\"/> <img class=\"cage\" src=\"" + ___HTML_LOADER_REPLACEMENT_11___ + "\"/> <div class=\"grass\"/> </div>";
// Exports
export default code;