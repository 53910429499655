// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#treasurehunt-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
}
#treasurehunt-wrapper #secret-input-container {
  text-align: center;
  margin: 10px;
}
#treasurehunt-wrapper #secret-input-container #secret-input-label {
  font-size: 1.5em;
}
#treasurehunt-wrapper #secret-input-container #secret-input {
  text-transform: uppercase;
  font-size: 1.5em;
}
#treasurehunt-wrapper #secret-input-container #view-secret-button {
  font-size: 1.5em;
}
#treasurehunt-wrapper #secret-answer {
  text-align: center;
  font-size: 2.5em;
  margin: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/treasurehunt/treasurehunt.less"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAFA;EAMQ,kBAAA;EACA,YAAA;AADR;AANA;EAUY,gBAAA;AADZ;AATA;EAcY,yBAAA;EACA,gBAAA;AAFZ;AAbA;EAmBY,gBAAA;AAHZ;AAhBA;EAyBQ,kBAAA;EACA,gBAAA;EACA,YAAA;AANR","sourcesContent":["@import (reference) \"../common/colors.less\";\n\n#treasurehunt-wrapper {\n    position: relative;\n    overflow: hidden;\n    padding-top: 50px;\n\n    #secret-input-container {\n        text-align: center;\n        margin: 10px;\n\n        #secret-input-label {\n            font-size: 1.5em;\n        }\n\n        #secret-input {\n            text-transform: uppercase;\n            font-size: 1.5em;\n        }\n\n        #view-secret-button {\n            font-size: 1.5em;\n        }\n\n    }\n\n    #secret-answer {\n        text-align: center;\n        font-size: 2.5em;\n        margin: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
