require("./maze.less");
import Confetti from "../common/confetti.js";
import exit from "./img/rainbow_96x96.png";
import icon from "./img/unicorn1_96x96.png";
import { getOffset } from "../common/tools";

const MAZE_HTML = require("./maze.html").default;

const DIFFICULTIES = {
    easy: { cols: 9, rows: 5, wallThickness: 8},
    medium: { cols: 14, rows: 8, wallThickness: 5},
    hard: { cols: 18, rows: 10, wallThickness: 3},
    hardest: { cols: 24, rows: 14, wallThickness: 3}
}

const PLAYER_MOVE_TOTAL_MILLIS = 250;

class Cell {
    constructor(col, row) {
        this.col = col;
        this.row = row;
        this.topWall = true;
        this.leftWall = true;
        this.bottomWall = true;
        this.rightWall = true;
        this.visited = false;
    }
}

export class Maze {

    constructor() {
        this.gameName = "Maze";
        this.icon = icon;
    }

    play($mainStage, loadMenu) {
        this.width = $mainStage.width();
        this.height = $mainStage.height();

        var mazeMainDiv = document.createElement("div");
        mazeMainDiv.classList = "maze-main";
        mazeMainDiv.style.width = this.width+"px";
        mazeMainDiv.style.height = this.height+"px";
        $mainStage.append(mazeMainDiv);
        var $mazeMainDiv = $(mazeMainDiv);
    
        $mazeMainDiv.append(MAZE_HTML);
        this.$nextGameMenu = $mazeMainDiv.find(".next-game-menu");
        this.$nextGameMenu.hide();

        this.$menuOverlay = $mazeMainDiv.find("#menu-overlay");

        var instance = this;
        $mazeMainDiv.find("a.new-game").click(function(event){
            event.preventDefault();
            let $this = $(this);
            if(instance.confetti) {
                instance.confetti.stopConfetti();
            }
            let newDifficulty = $this.attr("data-difficulty");
            instance.createNewMaze(newDifficulty);
            $this.parents(".menu").fadeOut("slow");
            instance.$menuOverlay.fadeOut("slow");
        });
        $mazeMainDiv.find("a.main-menu").click(function(event){
            event.preventDefault();
            loadMenu();
        });

        this.mazeCanvas = document.createElement("canvas");
        this.mazeCanvas.classList = "maze";
        this.mazeCanvas.width = this.width;
        this.mazeCanvas.height = this.height;
        mazeMainDiv.appendChild(this.mazeCanvas);
        this.mazeCtx = this.mazeCanvas.getContext("2d");

        var $confettiCanvas = $("<canvas class=\"confetti-canvas\"></canvas>");
        $confettiCanvas.attr("width", this.width);
        $confettiCanvas.attr("height", this.height);
        $mazeMainDiv.append($confettiCanvas);
        this.confetti = new Confetti($confettiCanvas.get(0));

        this.playerImg = new Image();
        this.playerImg.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgAQMAAADYVuV7AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABJJREFUeNpjYBgFo2AUjILhCQAE4AAB+I3MKgAAAABJRU5ErkJggg==";
        this.playerImg.classList = "player unicorn-sprite1";
        mazeMainDiv.appendChild(this.playerImg);

        this.exitImg = new Image();
        this.exitImg.src = exit;
        this.exitImg.classList = "exit";
        mazeMainDiv.appendChild(this.exitImg);

        var eventCaptureOverlay = document.createElement("div");
        eventCaptureOverlay.classList = "event-capture-overlay";
        eventCaptureOverlay.style.width = this.width+"px";
        eventCaptureOverlay.style.height = this.height+"px";
        mazeMainDiv.appendChild(eventCaptureOverlay);
        eventCaptureOverlay.addEventListener("click", function(event){
            var x = event.offsetX;
            var y = event.offsetY;
            instance.checkForPlayerMove(x, y);
        });
        eventCaptureOverlay.addEventListener("mousemove", function(event){
            var overlayOffset = getOffset(eventCaptureOverlay);
            var x = event.clientX - overlayOffset.left;
            var y = event.clientY - overlayOffset.top;
            instance.checkForPlayerMove(x, y);
        });
        function touchEventHandler(event) {
            var touch = event.touches[0];
            var overlayOffset = getOffset(eventCaptureOverlay);
            var x = touch.clientX - overlayOffset.left;
            var y = touch.clientY - overlayOffset.top;
            instance.checkForPlayerMove(x, y);
        }
        eventCaptureOverlay.addEventListener("touchstart", touchEventHandler);
        eventCaptureOverlay.addEventListener("touchmove", touchEventHandler);

        this.createNewMaze("medium");

        $mainStage.fadeIn("slow");
    }

    createNewMaze(newDifficulty) {
        this.difficulty = DIFFICULTIES[newDifficulty];
        
        this.cells = new Array(this.difficulty.cols);
        for(var x = 0; x < this.difficulty.cols; x++) {
            this.cells[x] = new Array(this.difficulty.rows);
        }

        for (var x = 0; x < this.difficulty.cols; x++) {
            for(var y = 0; y < this.difficulty.rows; y++) {
                this.cells[x][y] = new Cell(x, y);
            }
        }

        var current = this.cells[0][0];
        current.visited = true;
        var stack = [];
        do {
            var next = this.getNeighbor(current);
            if (next != null) {
                this.removeWall(current, next);
                stack.push(current);
                current = next;
                current.visited = true;
            } else {
                current = stack.pop();
            }
        } while(stack.length > 0);


        if(this.width/this.height < this.difficulty.cols/this.difficulty.rows) {
            this.cellSize = this.width/(this.difficulty.cols+1);
        } else {
            this.cellSize = this.height/(this.difficulty.rows+1);
        }
        this.hMargin = (this.width-this.difficulty.cols*this.cellSize)/2;
        this.vMargin = (this.height-this.difficulty.rows*this.cellSize)/2;

        this.playerImg.width = this.cellSize;
        this.playerImg.height = this.cellSize;
        
        this.exitImg.width = this.cellSize;
        this.exitImg.height = this.cellSize;

        this.drawMaze();
        
        this.player = this.cells[0][0];
        this.playerMoveDeltaX = 0;
        this.playerMoveDeltaY = 0;
        this.updatePlayerImg();
        this.exit = this.cells[this.difficulty.cols-1][this.difficulty.rows-1];
        this.updateAbsolutePosition(this.exitImg, this.exit);
    }

    updatePlayerImg() {
        this.playerX = this.hMargin + (this.player.col * this.cellSize) + this.playerMoveDeltaX;
        this.playerY = this.vMargin + (this.player.row * this.cellSize) + this.playerMoveDeltaY;
        this.playerImg.style.top = this.playerY+"px";
        this.playerImg.style.left = this.playerX+"px";
    }

    updateAbsolutePosition(element, cell) {
        element.style.top = this.vMargin + (cell.row * this.cellSize)+"px";
        element.style.left = this.hMargin + (cell.col * this.cellSize)+"px";
    }

    removeWall(current, next) {
        if(current.col == next.col) {
            if(current.row == next.row + 1) {
                current.topWall = false;
                next.bottomWall = false;
            } else {
                current.bottomWall = false;
                next.topWall = false;
            }
        } else if (current.col == next.col+1) {
            current.leftWall = false;
            next.rightWall = false;
        } else {
            current.rightWall = false;
            next.leftWall = false;
        }
    }

    getNeighbor(cell) {
        var neighbors = [];
        if(cell.col > 0 && !this.cells[cell.col-1][cell.row].visited) {
            neighbors.push(this.cells[cell.col-1][cell.row]);
        }
        if(cell.col < this.difficulty.cols-1 && !this.cells[cell.col+1][cell.row].visited) {
            neighbors.push(this.cells[cell.col+1][cell.row]);
        }
        if(cell.row > 0 && !this.cells[cell.col][cell.row-1].visited) {
            neighbors.push(this.cells[cell.col][cell.row-1]);
        }
        if(cell.row < this.difficulty.rows-1 && !this.cells[cell.col][cell.row+1].visited) {
            neighbors.push(this.cells[cell.col][cell.row+1]);
        }
        return neighbors <= 0 ? null : neighbors[this.getRandomInt(neighbors.length)];
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    drawMaze() {
        this.mazeCtx.clearRect(0, 0, this.mazeCanvas.width, this.mazeCanvas.height);

        this.mazeCtx.strokeStyle = "#f314e0";
        this.mazeCtx.lineWidth = this.difficulty.wallThickness;

        this.mazeCtx.save();
        this.mazeCtx.translate(this.hMargin, this.vMargin);
        for(var x = 0; x < this.difficulty.cols; x++) {
            for(var y = 0; y < this.difficulty.rows; y++) {
                var x1Px = x*this.cellSize;
                var y1Px = y*this.cellSize;
                var x2Px = (x+1)*this.cellSize;
                var y2Px = (y+1)*this.cellSize;
                var cell = this.cells[x][y];
                if(cell.topWall) {
                    this.drawLine(this.mazeCtx, x1Px, y1Px, x2Px, y1Px);
                }
                if(cell.rightWall) {
                    this.drawLine(this.mazeCtx, x2Px, y1Px, x2Px, y2Px);
                }
                if(cell.bottomWall) {
                    this.drawLine(this.mazeCtx, x1Px, y2Px, x2Px, y2Px);
                }
                if(cell.leftWall) {
                    this.drawLine(this.mazeCtx, x1Px, y1Px, x1Px, y2Px);
                }
            }
        }
        this.mazeCtx.restore();
    }

    drawLine(ctx, x1, y1, x2, y2) {
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
    }

    checkMovePlayer(direction) {
        switch(direction) {
            case "UP":
                if(!this.player.topWall) {
                    this.playerDest = this.cells[this.player.col][this.player.row-1];
                    this.prepMovePlayer(0, -1);
                }
                break;
            case "DOWN":
                if(!this.player.bottomWall) {
                    this.playerDest = this.cells[this.player.col][this.player.row+1];
                    this.prepMovePlayer(0, 1);
                }
                break;
            case "LEFT":
                if(!this.player.leftWall) {
                    this.playerDest = this.cells[this.player.col-1][this.player.row];
                    this.prepMovePlayer(-1, 0);
                }
                break;
            case "RIGHT":
                if(!this.player.rightWall) {
                    this.playerDest = this.cells[this.player.col+1][this.player.row];
                    this.prepMovePlayer(1, 0);
                }
                break;
        }
    }

    prepMovePlayer(totalDeltaX, totalDeltaY) {
        let instance = this;
        instance.playerMoving = true;
        let duration = PLAYER_MOVE_TOTAL_MILLIS;
        let end = Date.now() + duration;
        let step = function() {
            let remaining = end - Date.now();
            let rate = 0;
            if(remaining < 0) {
                instance.playerMoving = false;
                instance.player = instance.playerDest;
                instance.playerImg.classList = "player unicorn-sprite1";
            } else {
                rate = 1 - remaining/duration;
                let spriteNum = Math.floor(rate * 4) + 1;
                let spriteClass = "unicorn-sprite"+spriteNum;
                instance.playerImg.classList = "player " + spriteClass;
                requestAnimationFrame(step);
            }
            instance.playerMoveDeltaX = rate * totalDeltaX * instance.cellSize;
            instance.playerMoveDeltaY = rate * totalDeltaY * instance.cellSize;
            instance.updatePlayerImg();
            if(rate === 0 && !instance.checkExit()) {
                instance.checkForPlayerMove(instance.lastMouseTouchX, instance.lastMouseTouchY);
            }
        }
        step();
    }

    checkExit() {
        if(this.player == this.exit) {
            this.confetti.startConfetti();
            this.$menuOverlay.fadeIn("slow");
            this.$nextGameMenu.fadeIn("slow");
            return true;
        }
        return false;
    }

    checkForPlayerMove(x, y) {
        this.lastMouseTouchX = x;
        this.lastMouseTouchY = y;
        if(!this.playerMoving) {
            var playerCenterX = this.hMargin + (this.player.col + 0.5) * this.cellSize;
            var playerCenterY = this.vMargin + (this.player.row + 0.5) * this.cellSize;
            var dx = x - playerCenterX;
            var dy = y - playerCenterY;
            var absDx = Math.abs(dx);
            var absDy = Math.abs(dy);
            if(absDx > this.cellSize || absDy > this.cellSize) {
                if(absDx > absDy) {
                    if(dx > 0) {
                        this.checkMovePlayer("RIGHT");
                    } else {
                        this.checkMovePlayer("LEFT");
                    }
                } else {
                    if(dy > 0) {
                        this.checkMovePlayer("DOWN");
                    } else {
                        this.checkMovePlayer("UP");
                    }
                }
            }
        }
    }

}