require("./memory.less");
const HTML = require("./memory.html").default;
import { setClick, copyDimensions, shuffleArray } from "../common/tools";
//import WORD_LIST from './word-lists/hfwl-q4.txt';
import Confetti from "../common/confetti.js";
import anime from "animejs/lib/anime.es.js";
import icon from "./memory.png";

const TOTAL_CARDS = 30;
const MAX_CARDS_SELECTED = 2;

async function chooseWords(wordList) {
    const wordListContent = await import(`./word-lists/${wordList}.txt`);
    const allWords = wordListContent.default.split(/[\r\n]+/);
    shuffleArray(allWords);
    const numWordsToChoose = Math.floor(TOTAL_CARDS / 2);
    return allWords.slice(0, numWordsToChoose);
}

export class Memory {

    constructor() {
        this.gameName = "Memory";
        this.icon = icon;
        this.numCardsSelected = 0;
    }

    clearSelected() {
        const instance = this;
        let counter = 0;
        instance.$cardsContainer.find(".card-inner.selected").each((index, element) => {
            const $elem = $(element);
            $elem.fadeOut("fast", () => {
                counter++;
                $elem.removeClass("selected");
                if(counter >= MAX_CARDS_SELECTED) {
                    instance.$cardsContainer.removeClass("no-select");
                    instance.numCardsSelected = 0;
                }
            });
        });
    }

    checkAllMatched() {
        const instance = this;
        let allMatched = true;
        instance.$cardsContainer.find(".card").each((index, element) => {
            allMatched &= $(element).hasClass("matched");
        });
        if(allMatched) {
            instance.$confettiCanvas.show();
            instance.confetti.startConfetti();
            instance.showMenu(instance.$wonGameMenu);
        }
    }

    setMatched() {
        const instance = this;
        instance.$cardsContainer.find(".card-inner.selected").each((index, element) => {
            const $elem = $(element);
            anime({
                targets: $elem.get(0),
                backgroundColor: "rgb(140, 221, 109)",
                easing: "easeInOutQuad",
                duration: 250,
                complete: () => {
                    $elem.removeClass("selected");
                    $elem.parents(".card").addClass("matched");
                    instance.checkAllMatched();
                }
            });
        });
        instance.$cardsContainer.removeClass("no-select");
        instance.numCardsSelected = 0;
    }

    isAllSelectedMatch() {
        const instance = this;
        let lastValue = null;
        let allMatch = true;
        let numElems = 0;
        instance.$cardsContainer.find(".card-inner.selected").each((index, element) => {
            numElems++;
            const $elem = $(element);
            const thisValue = $elem.data("word");
            if(lastValue) {
                allMatch &= lastValue == thisValue;
            } else {
                lastValue = thisValue;
            }
        });
        return numElems > 1 && allMatch;
    }

    handleCardClick($cardInner, $card) {
        const instance = this;
        if(instance.numCardsSelected >= 2 || $card.hasClass("matched") || $cardInner.hasClass("selected")) {
            return;
        }
        instance.numCardsSelected++;
        instance.$cardsContainer.addClass("no-select");
        $cardInner.addClass("selected");
        $cardInner.fadeIn("fast", () => {
            if(instance.numCardsSelected > 1) {
                setTimeout(()=>{
                    if(instance.isAllSelectedMatch()) {
                        instance.setMatched();
                    } else {
                        instance.clearSelected();
                    }
                }, 1000);
            } else {
                instance.$cardsContainer.removeClass("no-select");
            }
        });
    }

    async nextCards(wordList) {
        const instance = this;
        instance.$cardsContainer.empty();
        instance.$cardsContainer.show();
        const chosenWords = await chooseWords(wordList);
        const allWords = chosenWords.concat(chosenWords);
        const useLargeFont = wordList === "shapes";
        shuffleArray(allWords);
        function addCard(i) {
            if(i >= TOTAL_CARDS) {
                return;
            }
            let currentText = allWords[i%allWords.length];
            let $cardText = $("<div class=\"card-text\">"+currentText+"</div>")
            if(useLargeFont) {
                $cardText.addClass("large-font");
            }
            let $cardInner = $("<div class=\"card-inner\"></div>").hide();
            $cardInner.append($cardText);
            $cardInner.data("word", currentText);
            let $card = $("<div class=\"card\"></div>");
            $card.click(event => {
                instance.handleCardClick($cardInner, $card);
            });
            $card.on({"touchstart": () => {
                instance.handleCardClick($cardInner, $card);
            }});
            $card.append($cardInner);
            instance.$cardsContainer.append($card.hide());
            $card.fadeIn(100, () => {
                addCard(i+1);
            });
        }
        addCard(0);
    }
    
    showMenu($menu) {
        this.$menuOverlay.fadeIn("fast", ()=> {
            $menu.fadeIn("fast");
        });
    }

    nextMenu($currentMenu, nextMenuId) {
        const instance = this;
        $currentMenu.fadeOut("fast", () => {
            instance.$memoryWrapper.find("#"+nextMenuId).fadeIn("fast");
        });
    }

    hideMenu($menu) {
        if($menu) {
            const instance = this;
            function doFadeOut() {
                $menu.fadeOut("fast", ()=> {
                    instance.$menuOverlay.fadeOut("fast");
                });
            }
            if(instance.confetti.isConfettiRunning()) {
                instance.$confettiCanvas.fadeOut("fast", () => {
                    instance.confetti.removeConfetti();
                    doFadeOut();
                }); 
            } else {
                doFadeOut();
            }
        }
    }

    startNewGame($menu, wordList) {
        const instance = this;
        instance.hideMenu($menu);
        instance.$cardsContainer.fadeOut("fast", () => {
            instance.nextCards(wordList);
        });
    }

    play($mainStage, loadMenu) {
        const instance = this;
        const $memoryWrapper = instance.$memoryWrapper = $(HTML);
        copyDimensions($mainStage, $memoryWrapper);

        instance.$menuOverlay = $memoryWrapper.find("#menu-overlay").hide();
        instance.$newGameMenu = $memoryWrapper.find("#new-game-menu").hide();
        instance.$wonGameMenu = $memoryWrapper.find("#won-game-menu").hide();
        $memoryWrapper.find("#new-game-list-menu").hide();

        setClick($memoryWrapper, ".menu a", event => {
            const $link = $(event.target);
            const $menu = $link.parents(".menu");
            if($link.hasClass("main-menu")) {
                loadMenu();
            } else if ($link.attr("data-word-list")) {
                instance.startNewGame($menu, $link.attr("data-word-list"));
            } else if ($link.attr("data-target")) {
                instance.nextMenu($menu, $link.attr("data-target"));
            } else {
                throw "Unknown link type!";
            }
        });

        instance.$confettiCanvas = $memoryWrapper.find("#confetti-canvas").hide();
        copyDimensions($mainStage, instance.$confettiCanvas);
        instance.confetti = new Confetti(instance.$confettiCanvas.get(0));

        instance.$cardsContainer = $memoryWrapper.find("#cards-container");

        instance.showMenu(instance.$newGameMenu);

        $mainStage.append($memoryWrapper);
        $mainStage.fadeIn("fast");
    }

}